"use strict";

function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }
function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter); }
function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }
function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i]; return arr2; }
var activePage = "home";
var chartBorderColor = getComputedStyle(document.body).getPropertyValue("--reverse-color");
window.mobileCheck = function () {
  var check = false;
  (function (a) {
    if (/(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(a) || /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(a.substr(0, 4))) check = true;
  })(navigator.userAgent || navigator.vendor || window.opera);
  return check;
};
var overlay = document.querySelector(".overlay");
function openOverlay() {
  overlay.classList.add("open");
}
function closeOverlay() {
  overlay.classList.remove("open");
}
function openLoading() {
  openOverlay();
  overlay.classList.add("loading");
}
function closeLoading() {
  closeOverlay();
  overlay.classList.remove("loading");
}
var header = document.querySelector(".header");
var headerScroll = document.querySelector(".header-scroll");
var lastScrollTop = 0;
window.addEventListener("scroll", function () {
  var st = window.pageYOffset || document.documentElement.scrollTop;
  if (!header) return;
  if (st > lastScrollTop) {
    header.classList.add("fixed");
  } else if (st < lastScrollTop) {
    header.classList.remove("fixed");
    header.classList.remove("fix-normal");
  }
  if (st == 0) {
    header.classList.add("fix-normal");
  }
  lastScrollTop = st <= 0 ? 0 : st; // For Mobile or negative scrolling
  getScrollPercent();
}, false);
function getScrollPercent() {
  var h = document.documentElement,
    b = document.body,
    st = "scrollTop",
    sh = "scrollHeight";
  headerScroll.querySelector("span").style.width = ((h[st] || b[st]) / ((h[sh] || b[sh]) - h.clientHeight) * 100).toFixed(2) + "%";
}
var theme = "light"; //default to light
function detectColorScheme() {
  //local storage is used to override OS theme settings
  if (localStorage.getItem("theme")) {
    if (localStorage.getItem("theme") == "dark") {
      theme = "dark";
    }
  } else if (!window.matchMedia) {
    //matchMedia method not supported
    return false;
  } else if (window.matchMedia("(prefers-color-scheme: dark)").matches) {
    //OS theme setting detected as dark
    theme = "dark";
  }

  //dark theme preferred, set document with a `data-theme` attribute
  if (theme == "dark") {
    document.documentElement.setAttribute("data-theme", "dark");
  } else {
    document.documentElement.setAttribute("data-theme", "light");
  }
  localStorage.setItem("theme", theme);
}
detectColorScheme();
var toggleSwitch = document.querySelectorAll('.theme-switch input[type="checkbox"]');
function switchTheme(e) {
  if (e.target.checked) {
    document.documentElement.setAttribute("data-theme", "dark");
    toggleSwitch.forEach(function (item) {
      item.checked = true;
    });
    theme = "dark";
  } else {
    document.documentElement.setAttribute("data-theme", "light");
    toggleSwitch.forEach(function (item) {
      item.checked = false;
    });
    theme = "light";
  }
  localStorage.setItem("theme", theme);
  if (document.querySelector(".market-chart")) {
    chartThemeHandler();
  }
}
toggleSwitch.forEach(function (item) {
  item.addEventListener("change", switchTheme, false);
});

//pre-check the dark-theme checkbox if dark-theme is set
if (document.documentElement.getAttribute("data-theme") == "dark") {
  toggleSwitch.forEach(function (item) {
    item.checked = true;
  });
}
var openAccordion = function openAccordion(accordion) {
  var content = accordion.querySelector(".accordion__content");
  accordion.classList.add("accordion__active");
  content.style.maxHeight = content.scrollHeight + "px";
  if (accordion.closest(".accordion__content")) {
    accordion.closest(".accordion__content").style.maxHeight = accordion.closest(".accordion__content").offsetHeight + content.scrollHeight + "px";
  }
};
var closeAccordion = function closeAccordion(accordion) {
  var content = accordion.querySelector(".accordion__content");
  accordion.classList.remove("accordion__active");
  content.style.maxHeight = null;
};
var accordions = document.querySelectorAll(".accordion");
accordions.forEach(function (accordion) {
  var intro = accordion.querySelector(".accordion__intro");
  var content = accordion.querySelector(".accordion__content");
  var familyaccordions = accordion.closest(".accordion-wrapper").querySelectorAll(".accordion");
  if (!intro) return;
  intro.onclick = function () {
    if (content.style.maxHeight) {
      closeAccordion(accordion);
    } else {
      familyaccordions.forEach(function (accordion) {
        return closeAccordion(accordion);
      });
      openAccordion(accordion);
    }
  };
});
var mobileMenu = document.querySelector(".mobile-menu");
document.querySelector(".hamburger").addEventListener("click", function (e) {
  mobileMenu.classList.add("open");
  document.body.classList.add("overflow");
});
document.querySelector(".close-btn").addEventListener("click", function (e) {
  mobileMenu.classList.remove("open");
  document.body.classList.remove("overflow");
});

// counter
function initCounters() {
  function handleIntersection(entries) {
    entries.map(function (entry) {
      if (entry.isIntersecting) {
        document.querySelectorAll(".animation-numbers:not(.visible) .count").forEach(function (counter) {
          animateValue(counter, parseFloat(counter.getAttribute("data-start")), parseFloat(counter.getAttribute("data-value")), 700);
        });
        entry.target.classList.add("visible");
      }
    });
  }
  var observer = new IntersectionObserver(handleIntersection);
  var component = document.querySelector(".animation-numbers");
  if (!component) return;
  observer.observe(component);
  function animateValue(obj, start, end, duration) {
    var startTimestamp = null;
    var step = function step(timestamp) {
      if (!startTimestamp) startTimestamp = timestamp;
      var progress = Math.min((timestamp - startTimestamp) / duration, 1);
      obj.innerHTML = Math.floor(progress * (end - start) + start);
      if (progress < 1) {
        window.requestAnimationFrame(step);
      }
    };
    window.requestAnimationFrame(step);
  }
}
initCounters();
function chartThemeHandler() {
  if (typeof homeChart === "undefined") return;
  homeChart.data.datasets[0].pointBorderColor = theme == "light" ? "#000" : "#fff";
  chartBorderColor = theme == "light" ? "#000" : "#fff";
  homeChart.options.scales.x.ticks.color = theme == "light" ? "#000" : "#fff";
  homeChart.options.scales.y.ticks.color = theme == "light" ? "#000" : "#fff";
  homeChart.update();
}
var dataTabs = document.querySelectorAll(".tab-content");
var dataLinks = document.querySelectorAll("[data-tab-target]");
function initTabs() {
  if (!dataTabs || dataTabs.length <= 0 || !dataLinks || dataLinks.length <= 0) return;
  dataLinks.forEach(function (item) {
    try {
      item.addEventListener("click", function (e) {
        _toConsumableArray(dataTabs).map(function (item) {
          return item.classList.remove("active");
        });
        _toConsumableArray(dataLinks).map(function (item) {
          return item.classList.remove("active");
        });
        document.querySelector("#" + item.dataset.tabTarget).classList.add("active");
        item.classList.add("active");
      });
    } catch (error) {
      console.log(error);
    }
  });
}
initTabs();
function mapTabHandler() {
  if (!dataTabs || dataTabs.length <= 0 || !dataLinks || dataLinks.length <= 0) return;
  dataLinks.forEach(function (item) {
    try {
      item.addEventListener("click", function (e) {
        _toConsumableArray(document.querySelectorAll(".map-accordion-block .accordion")).map(function (item) {
          return item.classList.remove("accordion__active");
        });
        _toConsumableArray(document.querySelectorAll(".map-accordion-block .accordion__content")).map(function (item) {
          return item.removeAttribute("style");
        });
        document.querySelectorAll(".tab-content.active .accordion")[0].querySelector(".accordion__intro").click();
        _toConsumableArray(document.querySelectorAll(".map-content--item .map-item--on")).map(function (item) {
          return item.removeAttribute("style");
        });
        document.querySelector(".map-content--item").classList.remove("tr", "east", "asia");
        document.querySelector(".map-content--item").classList.add(item.dataset.mapTarget);
      });
    } catch (error) {
      console.log(error);
    }
  });
  document.querySelectorAll(".accordion__intro").forEach(function (item) {
    item.addEventListener("click", function (e) {
      _toConsumableArray(document.querySelectorAll(".map-content--item .map-item--on")).map(function (item) {
        return item.classList.remove("active");
      });
      if (item.dataset.mapTarget) {
        setTimeout(function () {
          // console.log( document.querySelector('#'+item.dataset.mapTarget))
          document.querySelector("#" + item.dataset.mapTarget).classList.add("active");
        }, 150);
      }
    });
  });
  document.querySelectorAll(".map-item--on").forEach(function (item) {
    item.addEventListener("click", function (e) {
      document.querySelector("[data-map-target=\"".concat(item.id, "\"]")).click();
    });
  });
  document.querySelectorAll(".tab-content.active .accordion")[0].querySelector(".accordion__intro").click();
}
mapTabHandler();

// butonların aktif pasif ayarını yapma
function radioButtonGroup(buttonGroup) {
  buttonGroup.addEventListener("click", function (event) {
    if (buttonGroup !== null && buttonGroup !== void 0 && buttonGroup.querySelector(".active")) {
      buttonGroup.querySelector(".active").classList.remove("active");
    }
    event.target.classList.add("active");
  });
}

//for chart
var customWidth, customHeight, gradient;
function getGradient(ctx, chartArea) {
  var chartWidth = chartArea.right - chartArea.left;
  var chartHeight = chartArea.bottom - chartArea.top;
  if (!gradient || customWidth !== chartWidth || customHeight !== chartHeight) {
    // Create the gradient because this is either the first render
    // or the size of the chart has changed
    customWidth = chartWidth;
    customHeight = chartHeight;
    gradient = ctx.createLinearGradient(500, 0, 100, 0);
    gradient.addColorStop(1, "#744494");
    gradient.addColorStop(0.75, "#A52E8A");
    gradient.addColorStop(0.5, "#C92D63");
    gradient.addColorStop(0.25, "#DE5D36");
    gradient.addColorStop(0, "#E88E47");
  }
  return gradient;
}
var homeSliders = document.querySelectorAll(".home-slider");
var homeThumbSliders = document.querySelectorAll(".home-slider-thumb");
if ((homeSliders === null || homeSliders === void 0 ? void 0 : homeSliders.length) > 0 && (homeThumbSliders === null || homeThumbSliders === void 0 ? void 0 : homeThumbSliders.length) > 0) {
  for (var homeSliderIndex = 0; homeSliderIndex < homeSliders.length; homeSliderIndex++) {
    var homeSlider = homeSliders[homeSliderIndex];
    var homeThumbSlider = homeThumbSliders[homeSliderIndex];
    new Swiper(homeSlider, {
      loop: true,
      spaceBetween: 0,
      slidesPerView: 1,
      thumbs: {
        swiper: new Swiper(homeThumbSlider, {
          loop: true,
          spaceBetween: 10,
          slidesPerView: 3,
          freeMode: true,
          watchSlidesProgress: true
        })
      }
    });
  }
}
var charComponent = document.querySelector("#myChart");
if (charComponent) {
  var _homeChart = new Chart(charComponent, {
    type: "line",
    data: {
      labels: ["26.09.2022", "27.09.2022", "28.09.2022", "29.09.2022", "30.09.2022"],
      datasets: [{
        data: [100.45, 111.1, 115.87, 99.11, 100],
        fill: false,
        borderColor: function borderColor(context) {
          var chart = context.chart;
          var ctx = chart.ctx,
            chartArea = chart.chartArea;
          if (!chartArea) {
            return;
          }
          return getGradient(ctx, chartArea);
        },
        borderWidth: 6,
        tension: 0.1,
        pointRadius: 7,
        pointHoverRadius: 7,
        pointBorderWidth: 3,
        pointBorderColor: chartBorderColor,
        pointBackgroundColor: "#fff"
      }]
    },
    options: {
      responsive: true,
      maintainAspectRatio: mobileCheck() ? false : true,
      plugins: {
        legend: {
          display: false
        },
        tooltip: {
          displayColors: false,
          xAlign: "center"
        }
      },
      scales: {
        x: {
          ticks: {
            align: "end",
            color: theme == "light" ? "#455560" : "#fff",
            padding: 20
          }
        },
        y: {
          min: 0,
          max: 250,
          ticks: {
            padding: 20,
            stepSize: 50,
            color: theme == "light" ? "#455560" : "#fff"
          }
        }
      }
    }
  });
}
var promoSlider = document.querySelector(".home-promo-slider");
if (promoSlider) {
  new Swiper(promoSlider, {
    // loop: true,
    autoplay: false,
    spaceBetween: 21,
    slidesPerView: 1,
    watchSlidesProgress: true,
    pagination: {
      el: ".swiper-pagination",
      type: "bullets"
    },
    breakpoints: {
      480: {
        slidesPerView: 3,
        slidesPerGroup: 2
      }
    }
  });
}
var followSlider = document.querySelector(".follow-us--slider");
if (followSlider) {
  new Swiper(".follow-us--slider", {
    loop: true,
    autoplay: true,
    pagination: {
      el: ".swiper-pagination",
      type: "bullets"
    }
  });
}
var pageSlider = document.querySelector(".innerpage-slider");
if (pageSlider) {
  new Swiper(pageSlider, {
    loop: true,
    spaceBetween: 0,
    slidesPerView: 1,
    pagination: {
      el: ".swiper-pagination"
    }
  });
}
var rewardsSlider = document.querySelector(".rewards-slider");
if (rewardsSlider) {
  new Swiper(rewardsSlider, {
    slidesPerView: 1,
    spaceBetween: 25,
    navigation: {
      nextEl: ".rewards-slider .next-slide-btn",
      prevEl: ".rewards-slider .prev-slide-btn"
    },
    breakpoints: {
      768: {
        slidesPerView: "auto"
      }
    }
  });
}
var newsSlider = document.querySelector(".news-slider");
if (rewardsSlider) {
  new Swiper(newsSlider, {
    slidesPerView: 1,
    spaceBetween: 25,
    navigation: {
      nextEl: ".news-slider .next-slide-btn",
      prevEl: ".news-slider .prev-slide-btn"
    },
    breakpoints: {
      768: {
        slidesPerView: "auto"
      }
    }
  });
}
var milestonesSlider = document.querySelector(".milestones-slider");
if (milestonesSlider) {
  new Swiper(milestonesSlider, {
    slidesPerView: 1,
    spaceBetween: 25,
    autoHeight: true,
    pagination: {
      el: ".milestones-pagination",
      clickable: true,
      bulletClass: "milestone-bullet",
      bulletActiveClass: "active",
      type: "bullets",
      renderBullet: function renderBullet(index, className) {
        return '<button type="button" class="' + className + '"><span></span>' + this.slides[index].dataset.date + "</button>";
      },
      dynamicBullets: true
    },
    navigation: {
      nextEl: ".milestones-button-next",
      prevEl: ".milestones-button-prev"
    },
    breakpoints: {
      768: {
        autoHeight: false,
        pagination: {
          dynamicBullets: false
        }
      }
    }
  });
}
var videoButton = document.querySelector(".video-block button");
if (videoButton) {
  document.querySelector(".video-block button").addEventListener("click", function (e) {
    var parentDiv = e.target.closest(".video-block");
    parentDiv.querySelector("iframe").setAttribute("src", parentDiv.dataset.video + "&autoplay=1");
    parentDiv.classList.add("open-video");
  });
}
var brandList = document.querySelector(".brand-list");
if (brandList && typeof Isotope !== "undefined") {
  var grid = new Isotope(".brand-list", {
    itemSelector: ".brand-item",
    layoutMode: "fitRows",
    resize: false
  });
  grid.arrange({
    filter: ".group-0"
  });
  var filtersElem = document.querySelector(".filter-menu");
  filtersElem.addEventListener("click", function (event) {
    grid.arrange({
      filter: event.target.getAttribute("data-filter")
    });
  });
  document.querySelector(".filter-menu_select").addEventListener("change", function (e) {
    grid.arrange({
      filter: e.target.value
    });
  });
  radioButtonGroup(filtersElem);
}
"use strict";

var customizeBoxBtn = document.querySelector('.home-promo-section .custom-btn');
var customizeWrapper = document.querySelector('.home-promo-customize');
var pinItemBtns = document.querySelectorAll('.promo-customize-item button');
var pinnedItems = [];
try {
  customizeBoxBtn.addEventListener('click', function (e) {
    openAccordion(customizeWrapper);
  });
  document.querySelector('.promo-customize--header').addEventListener('click', function (e) {
    closeAccordion(customizeWrapper);
  });
} catch (error) {}
pinItemBtns.forEach(function (item) {
  item.addEventListener('click', function (e) {
    if (item.parentElement.classList.contains('pinned')) {
      item.parentElement.classList.remove('pinned');
      pinnedItems.splice(pinnedItems.findIndex(function (pin) {
        return pin == item.parentElement.dataset.promoId;
      }), 1);
    } else {
      if (pinnedItems.length == 3) {
        return;
      }
      item.parentElement.classList.add('pinned');
      pinnedItems.push(item.parentElement.dataset.promoId);
    }
  });
});